.wrap-input {
  display: flex;
  align-items: flex-start;
}

.wrap-input h4 {
  min-width: 150px;
  text-align: end;
  padding-right: 12px;
}
.wrap-select {
  width: 50%;
}

.wrap-selected {
  margin-top: 16px;
  margin-left: 150px;
  background-color: #fff5e7;
  max-width: 60%;
  padding: 16px;
  border-radius: 8px;
  position: relative;
}

.close-selected {
  background-color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 6px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.show-selected {
  display: flex;
}

.wrap-uploadfile-upload {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wrap-uploadfile-upload p {
  min-width: 150px;
  text-align: end;
  padding-right: 12px;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type='file'] {
  display: none;
}

.file > label {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204, 86%, 53%);
  border-color: hsl(204, 86%, 53%);
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

.tgl-periksa {
  display: flex;
  align-items: center;
}

.tgl-periksa p {
  min-width: fit-content;
  padding-right: 6px;
}

.tgl-periksa input {
  background-color: #f0f4f9;
  height: 36px;
  outline: none;
  border: none;
  padding: 0 12px;
}

.wrap-form-input {
  display: flex;
  align-items: center;
  width: 100%;
}
.wrap-form-input input {
  outline: none;
  border: none;
  padding: 12px 8px;
  background-color: #f0f4f9;
  padding: 0 12px;
  width: 50%;
  height: 35px;
  margin-right: 4px;
  border-radius: 4px;
}
.wrap-form-input button {
  outline: none;
  border: none;
  padding: 0 16px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f24141;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .wrap-input {
    flex-direction: column;
    align-items: flex-start;
  }

  .wrap-input h4 {
    min-width: 150px;
    text-align: left;
    padding-right: 12px;
  }

  .wrap-selected {
    margin-top: 16px;
    margin-left: 0;
    background-color: #fff5e7;
    max-width: 100%;
    padding: 16px;
    border-radius: 8px;
    position: relative;
  }

  .show-selected {
    display: flex;
    flex-direction: column;
  }

  .tgl-periksa {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }

  .tgl-periksa input {
    background-color: #f0f4f9;
    height: 36px;
    outline: none;
    border: none;
    padding: 0 12px;
    width: 100%;
    border-radius: 4px;
  }
}
