.testingstyle {
  width: 100%;
}

.cke_top {
  background-color: #f0f4f9 !important;
}

.cke_bottom {
  background-color: #f0f4f9 !important;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type='file'] {
  display: none;
}

.file > label {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204, 86%, 53%);
  border-color: hsl(204, 86%, 53%);
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

.wrap-audience {
  display: flex;
}

.box-selected-audience {
  display: flex;
  align-items: center;
  margin-right: 12px;
  border: 0.5px solid #878888;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  /* background-color: #f1faff; */
  position: relative;
}

.box-selected-audience.active {
  display: flex;
  align-items: center;
  margin-right: 12px;
  border: 0.5px solid #009ef7;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1faff;
}

.box-selected-audience > label {
  margin-right: 6px;
  cursor: pointer;
  color: #9e9e9e;
}

.box-selected-audience.active > label {
  margin-right: 6px;
  cursor: pointer;
  color: #009ef7;
  font-weight: bold;
}

.ckeditor-wrap {
  display: flex;
}

.wrap-uploadfile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-upload {
  display: flex;
  padding: 12px;
  background: #f1faff;
  margin-top: 12px;
}

.list-upload > p {
  flex: 1;
  color: #009ef7;
  font-weight: bold;
}

.header-table-action {
  display: flex;
  align-items: center;
}

.header-table-action p {
  margin-right: 12px;
}
.header-table-action button {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}
.header-table-action button.active {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  background-color: #4e5cf2;
  color: white;
}
.header-table-action button.active:last-child {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  background-color: #f24141;
  color: white;
}

.header-table-action button p {
  margin-right: 4px;
}

.head-toolbar-action {
  padding-top: 12px;
  padding-bottom: 12px;
}
.head-toolbar-action.active {
  background-color: #053ed01c;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  border-radius: 4px;
}

.checkTab.Mui-selected {
  background-color: #053ed01c !important;
}
.checkTab.Mui-checked {
  color: #4e5cf2 !important;
}

.action-tab-hasil-rujukan {
  display: flex;
  align-items: center;
  justify-content: left;
}
.action-tab-hasil-rujukan button {
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 16px;
  cursor: pointer;
}
.action-tab-hasil-rujukan button:hover {
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 16px;
  background-color: #4e5cf2;
  color: white;
  cursor: pointer;
}
.table-head-hasil-rujukan {
  background-color: #f6f8fa;
}

.btn-daftar-rujukan {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.btn-daftar-rujukan button {
  border: none;
  outline: none;
  padding: 11px 16px;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 16px;
  background-color: #043ed0;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-style-rujuk {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: flex-end;
}

.search-style-rujuk input {
  border: none;
  outline: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-width: 100%;
}
.search-style-rujuk button {
  border: none;
  outline: none;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #dcdcdc;
  color: black;
}

.search-style-periksa {
  display: flex;
  align-items: center;
  /* width: 80%; */
  justify-content: center;
  margin: 0 auto;
}

.search-style-periksa input {
  border: none;
  outline: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 80%;
}
.search-style-periksa button {
  border: none;
  outline: none;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #dcdcdc;
  color: black;
}

.subtitle-card-add {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 12px;
}

.subtitle-card-add p {
  margin-right: 12px;
  min-width: 150px;
  text-align: right;
}

.wrap-pasien-rujukan {
  background-color: white;
  padding: 24px;
  border-radius: 4px;
}

.wrap-input-form {
  display: flex;
  width: 100%;
}
.wrap-input-form-textarea {
  width: 100%;
}

.wrap-input-add {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 45%;
}
.wrap-input-add-textarea {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
  align-items: flex-start;
}
.wrap-input-add-textarea textarea {
  width: 72.3%;
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-radius: 4px;
  min-height: 150px;
}
.wrap-input-add-textarea p {
  text-align: right;
  margin-right: 12px;
  min-width: 16.7%;
}

.wrap-input-add p {
  text-align: right;
  margin-right: 12px;
  min-width: 37%;
}

.wrap-input-add input {
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-radius: 4px;
  width: 80%;
}

@media screen and (max-width: 768px) {
  .ckeditor-wrap {
    display: flex;
    flex-direction: column;
  }
  .ckeditor-wrap > p {
    margin-bottom: 6px;
  }

  .btn-daftar-rujukan button {
    border: none;
    outline: none;
    padding: 11px 16px;
    border-radius: 4px;
    margin-right: 6px;
    font-size: 16px;
    background-color: #043ed0;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .search-style-rujuk {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .search-style-rujuk input {
    border: none;
    outline: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-width: 0;
    width: 100%;
  }
  .btn-daftar-rujukan {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    flex-direction: column;
  }

  .add-btn-rujuk {
    width: 100%;
    margin-top: 12px;
  }
  .add-btn-rujuk button {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrap-input-form {
    display: flex;
    width: 100% !important;
    flex-direction: column;
  }
  .wrap-input-add {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 100% !important;
    flex-direction: column;
  }

  .wrap-input-add p {
    text-align: left;
    margin-right: 12px;
    min-width: 37%;
    margin-bottom: 6px;
  }

  .wrap-input-add input {
    outline: none;
    border: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-radius: 4px;
    width: 100% !important;
  }

  .wrap-input-add-textarea {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: 100% !important;
    align-items: flex-start;
    flex-direction: column;
  }

  .wrap-input-add-textarea textarea {
    width: 100% !important;
    outline: none;
    border: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-radius: 4px;
    min-height: 150px;
  }

  .wrap-input-add-textarea p {
    text-align: left;
    margin-right: 12px;
    min-width: 16.7%;
    margin-bottom: 6px;
  }
}
